import { injectable } from "inversify";
import AIRepository from "../../../domain/repositories/AIRepository";
import GetAISummariesByIdApiImpl from "./api/impl/GetAISummariesApiImpl";
import GetAISummaryByRecordingApiImpl from "./api/impl/GetAISummaryByRecordingApiImpl";
import GetAIEmbedDashboardApiImpl from "./api/impl/GetAIEmbedDashboardApiImpl";
import SearchAISummariesApiImpl from "./api/impl/SearchAISummariesApiImpl";
import SearchAISummariesLanguagesApiImpl from "./api/impl/SearchAISummariesLanguagesApiImpl";
import SearchAISummariesEntitiesApiImpl from "./api/impl/SearchAISummariesEntitiesApiImpl";
import GetAIAgentsApiImpl from "./api/impl/GetAIAgentsApiImpl";
import GetAICustomersApiImpl from "./api/impl/GetAICustomersApiImpl";
import GetAISummaryByUserApiImpl from "./api/impl/GetAISummaryByCustomerApiImpl";
import UpdateAISummaryByRecordingApiImpl from "./api/impl/UpdateAISummaryByRecordingApiImpl";
import GetAIMomentsApiImpl from "./api/impl/GetAIMomentsApiImpl";
import UpdateAIMomentByIdApiImpl from "./api/impl/UpdateAIMomentByIdApiImpl";
import CreateAIMomentByIdApiImpl from "./api/impl/CreateAIMomentByIdApiImpl";
import DeleteAIMomentByIdApiImpl from "./api/impl/DeleteAIMomentByIdApiImpl";
@injectable()
class AIRepositoryDev implements AIRepository {
    getAISumaries = async (params: string): Promise<any> =>  GetAISummariesByIdApiImpl(params);
    getAISumaryByRecord = async (record: string): Promise<any> => GetAISummaryByRecordingApiImpl(record);
    updateAISumaryByRecord = async (record: string, content: any): Promise<any> => UpdateAISummaryByRecordingApiImpl(record, content);
    getAIEmbedURL = async (): Promise<any> => GetAIEmbedDashboardApiImpl();
    searchAISummaries = async (query: any): Promise<any> => SearchAISummariesApiImpl(query);
    searchAISummariesLanguages = async (): Promise<any> => SearchAISummariesLanguagesApiImpl();
    searchAISummariesEntities = async (): Promise<any> => SearchAISummariesEntitiesApiImpl();
    getAIAgents = async(customer: string): Promise<any> => GetAIAgentsApiImpl(customer);
    getAICustomers = async(): Promise<any> => GetAICustomersApiImpl();
    getAIPerson = async(query: any, type: string, params: string): Promise<any> => GetAISummaryByUserApiImpl(query, type, params);
    getAIMoments = async(keywords: string[], page: number, type: string, customer: string, lob: string): Promise<any> => GetAIMomentsApiImpl(keywords, page, type, customer, lob);
    UpdateAIMomentById = async(content: any, keyword_id: number, value: any | undefined): Promise<any> =>  UpdateAIMomentByIdApiImpl(content, keyword_id, value);
    CreateAIMoment = async(customer: string, lob: string, type: string, description: string, weight: number) => CreateAIMomentByIdApiImpl(customer, lob, type, description, weight);
    DeleteAIMoment = async(keyword_id: number) => DeleteAIMomentByIdApiImpl(keyword_id); 
}

export default AIRepositoryDev;