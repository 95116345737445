import { useContext, useEffect, useRef, useState } from 'react';

import { Button, ContentLayout, Grid } from '@cloudscape-design/components';

import { ContactTable } from './ContactTable';
import di from '../../../di/DependencyInjection';
import { GetAISummariesUseCase, GetAISummariesUseCaseName } from '../../../domain/use_cases/ai/GetAISummariesUseCase';
import { isRight } from 'fp-ts/lib/Either';
import { GetAICustomersUseCase, GetAICustomersUseCaseName } from '../../../domain/use_cases/ai/GetAICustomersUseCase';
import { GetAIAgentsUseCase, GetAIAgentsUseCaseName } from '../../../domain/use_cases/ai/GetAIAgentsUseCase';
import { useForm } from 'react-hook-form';
import { GetAIPersonUseCase, GetAIPersonUseCaseName } from '../../../domain/use_cases/ai/GetAIPersonUseCase';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { Select } from './components/Select';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/dictionaries/KeyWordLocalization';

interface HomePageProps {
}

const AiPage: React.FC<HomePageProps> = () => {

    const formFunctions = useForm();
    const { formState: { errors }, } = formFunctions;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const [ data, setData ] = useState<any[]>([]);
    const [ urlParamsAgents, setUrlParamsAgents ] = useState<string>("");
    const [ urlParamsCustomer, setUrlParamsCustomer ] = useState<string>("");
    const [ urlParams, setUrlParams ] = useState<string>("");

    const hasFetchedData = useRef(false);

    const [ customers, setCustomers ] = useState<any[]>([]);
    
    const [ lobSelected, setLobSelected ] = useState<any[]>([]);
    const [ lobSelectedFromInput, setLobSelectedFromInput ] = useState<any>(null);
    const [ lobs, setLobs ] = useState<any[]>([]);
    const [ agents, setAgents ] = useState<any[]>([]);
    const [ agentsData, setAgentsData ] = useState<any[]>([]);
    const [ agentValue, setAgentValue ] = useState<string>('');

    const [ itemSelected, setItemSelected ] = useState('');

    const [ filterSelected, setFilterSelected ] = useState('all');

    const [ isLoading, setIsLoading ] = useState(false);
    
    const [query, setQuery] = useState<any>(
        { 'customer': '', 'agent': '', 'user_id': '', 'lob': '', 'aiEvaluated': '', 'manualEvaluated': '', 'dateInit': '', 'dateEnd': '' }
    );
    
    const _getCallList = async () => await di.get<GetAISummariesUseCase>(GetAISummariesUseCaseName)._aiRepository.getAISumaries(urlParams);

    const _getCustomers = async () => await di.get<GetAICustomersUseCase>(GetAICustomersUseCaseName)._aiRepository.getAICustomers();
    const _getAgents = async (customer: string) => await di.get<GetAIAgentsUseCase>(GetAIAgentsUseCaseName)._aiRepository.getAIAgents(customer);

    const firstCall = async () => {
        setIsLoading(true);
        const response = await _getCallList();
        const responseCustomers = await _getCustomers();
        const responseAgents = await _getAgents('');
        setIsLoading(false);

        let oldData: any = [];
        
        if (urlParams && urlParams !== '') {
            oldData = data;
        }

        if (isRight(responseCustomers)) {
            try {
                let result = JSON.parse(responseCustomers.right as string);
                const keysArray = Object.keys(result.data);
                setLobs(result.data);
                setCustomers(keysArray);
            } catch (error) {
                setLobs([]);
                setData([]);
                setCustomers([]);
                setAgents([]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                setUrlParams(``);
            }
            
        }
        if (isRight(responseAgents)) {
            try {
                let result = JSON.parse(responseAgents.right as string);
                let agents = result.data.map((user: any) => {
                    return user.firstname + "-" + user.lastname;
                });
                
                setAgents(agents);
                setAgentsData(result.data);
            } catch (error) {
                setData([]);
                setCustomers([]);
                setAgents([]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                setUrlParams(``);
            }
            
        }
        
        if (isRight(response)) {
            try {
                let result = JSON.parse(response.right as string);
                setData([...result["Records"], ...oldData]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                let url = ``;
                if (result["timestampFrom"]) {
                    url = (`limit=20&nextToken=${result["nextToken"]}`);
                    
                }
                setUrlParams(url);
            } catch (error) {
                setData([]);
                setCustomers([]);
                setAgents([]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                setUrlParams(``);
            }
            
        }
        

    }

    const onLoadTranscirption = () => {
        if (filterSelected === 'all') {
            firstCall();
        } else {
            _handleSearchWithfilters(query);
        }
    }
    
    if (!hasFetchedData.current) {
        firstCall();
        hasFetchedData.current = true;  // Mark that the data has been fetched
    }

    const _handleSearchWithfilters = async (queryValue: any) => {
        
        if (queryValue.agent !== '') {
            
            let user_id = agentsData.filter((item) => item.firstname + "-" + item.lastname === queryValue.agent)[0]["user_id"];
            let agent_name = agentsData.filter((item) => item.firstname + "-" + item.lastname === queryValue.agent)[0]["firstname"] + '-' + agentsData.filter((item) => item.firstname + "-" + item.lastname === queryValue.agent)[0]["lastname"];

            queryValue['agent'] = agent_name;
            queryValue['user_id'] = user_id;
            setAgentValue(user_id)
        }
        
        setIsLoading(true);
        setFilterSelected('any');

        let oldData: any = [];
        if (urlParamsCustomer !== '') {
            oldData = data;
        }

        let oldUrl = '';
        
        let queryItems = queryValue.manualEvaluated + "_" + queryValue.customer + "_" + queryValue.lob + "_" + queryValue.agent + "_" + queryValue.aiEvaluated + "_" + queryValue.dateInit + "_" + queryValue.dateEnd;
        
        if (queryItems === itemSelected) {
            oldUrl = urlParamsCustomer;
        } else {
            oldData = [];
        }

        const _getPerson = async () => await di.get<GetAIPersonUseCase>(GetAIPersonUseCaseName)._aiRepository.getAIPerson(queryValue, 'customer', oldUrl);
        const response = await _getPerson();
        const responseAgents = await _getAgents(queryValue.customer);
        setIsLoading(false);

        if (isRight(response)) {
            let result = JSON.parse(response.right as string);
            try {
                setData([...result['Records'], ...oldData]);
                setUrlParams(``);
                setUrlParamsAgents(``);
                let url = ``;
                if (result["nextToken"]) {
                    url = (`limit=20&nextToken=${result["nextToken"]}`);   
                }
                setUrlParamsCustomer(url);
            } catch (error) {
                setUrlParamsCustomer('');
                setData([]);
            }
            
        }

        if (isRight(responseAgents)) {
            try {
                let result = JSON.parse(responseAgents.right as string);
                let agents = result.data.map((user: any) => {
                    return user.firstname + "-" + user.lastname;
                });
                
                setAgents(agents);
                setAgentsData(result.data);
            } catch (error) {
                setData([]);
                setCustomers([]);
                setAgents([]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                setUrlParams(``);
            }
            
        }
        
    }

    const filterEmptyKeys = (obj: any) => {
        const shouldKeep = (v: any) => (Array.isArray(v) ? v.length > 0 : v !== null);
        
        return Object.fromEntries(
            Object.entries(obj).filter(([_, v]) => shouldKeep(v))
        );
    };
    const _handleQueryInput = (input: any, field: any) => {
        setQuery((q: any) => filterEmptyKeys({ ...q, [field]: input }));
        let newData = filterEmptyKeys({ ...query, [field]: input });
        if (field === 'customer' && input !== '') {
            setLobSelected(lobs[input]);
        }
        if (field === 'agent') {
            setAgentValue(input)
        }
        
        setItemSelected(newData.manualEvaluated + "_" + newData.customer + "_" + newData.lob + "_" + newData.agent + "_" + newData.aiEvaluated + "_" + newData.dateInit + "_" + newData.dateEnd);
        _handleSearchWithfilters(newData);
    }

    return (
        <div style={{padding: "20px"}}>
            <ContentLayout
                headerBackgroundStyle={"transparent"}
            >
                    {
                        (customers.length > 0 )  &&
                        <>
                        {
                            /**
                             * 
                             * <div className='search_section'>
                                <div className='search_section_inner'>
                                    <div onClick={() => {
                                        _handleSearch();
                                    }}>Search</div>
                                    <div onClick={() => {
                                        _handleSearch();
                                    }} style={{marginLeft: "5px"}}>
                                        <Icons.Search />
                                    </div>
                                </div>
                                
                            </div>
                             */
                        }
                            
                            <div className='filter_by_person'>
                            <div>
                                <p>Select an Agent, Customer or LOB to filter</p>
                            </div>
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                <div style={{width: '50%'}}>
                                <p style={{marginTop: '10px'}}>Customer</p>
                                <Select
                                    className="flex-grow-1 anget_filter_up"
                                        options={(customers || []).map((code, i) => ({
                                            label: code,
                                            value: code,
                                        }))}
                                    onChange={(event: any) =>
                                        _handleQueryInput(event.value, "customer")
                                    }
                                    value={
                                        query.customer
                                            ? { label: query.customer, value: query.customer }
                                            : null
                                        }
                                />
                                </div>
                                <div style={{width: '10px'}}></div>
                                <div style={{width: '50%'}}>
                                <p style={{marginTop: '10px'}}>LOB</p>
                                <Select
                                    className="flex-grow-1 anget_filter_up"
                                        options={(lobSelected || []).map((code, i) => ({
                                            label: code,
                                            value: code,
                                        }))}
                                    onChange={(event: any) =>
                                        _handleQueryInput(event.value, "lob")
                                    }
                                    value={
                                        query.lob
                                            ? { label: query.lob, value: query.lob }
                                            : null
                                        }
                                />
                                </div>
                                
                            </div>
                            <p style={{marginTop: '10px'}}>Agent</p>
                                <Select
                                    className="flex-grow-1 anget_filter"
                                        options={(agents || []).map((code, i) => ({
                                            label: code,
                                            value: code,
                                        }))}
                                    onChange={(event: any) =>
                                        _handleQueryInput(event.value, "agent")
                                    }
                                    value={
                                        query.agent
                                            ? { label: query.agent, value: query.agent }
                                            : null
                                        }
                                />
                                
                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                            <div style={{width: '50%'}}>
                                <p style={{marginTop: '10px'}}>AI Evaluated</p>
                                <Select
                                    className="flex-grow-1 anget_filter_down"
                                        options={(['True', 'False']).map((code, i) => ({
                                            label: code,
                                            value: code,
                                        }))}
                                    onChange={(event: any) =>
                                        _handleQueryInput(event.value, "aiEvaluated")
                                    }
                                    value={
                                        query.aiEvaluated
                                            ? { label: query.aiEvaluated, value: query.aiEvaluated }
                                            : null
                                        }
                                />
                            </div>
                            <div style={{width: '10px'}}></div>
                                <div style={{width: '50%'}}>
                                    <p style={{marginTop: '10px'}}>Manual Evaluated</p>
                                    <Select
                                        className="flex-grow-1 anget_filter_down"
                                            options={(['True', 'False']).map((code, i) => ({
                                                label: code,
                                                value: code,
                                            }))}
                                        onChange={(event: any) =>
                                            _handleQueryInput(event.value, "manualEvaluated")
                                        }
                                        value={
                                            query.manualEvaluated
                                                ? { label: query.manualEvaluated, value: query.manualEvaluated }
                                                : null
                                            }
                                    />
                                </div>
                            </div>
                            <form>
                                <div className="row">
                                    <div className={`form-group col-4 mt-1 ${errors.dateInit ? 'error' : ''}`}  style={{width: '50%'}}>
                                        <p style={{marginTop: '10px'}}>{i18n(KeyWordLocalization.TrackingDashboardPageDateInit)}</p>
                                        <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) => {
                                                if (new Date(query.dateEnd) >= new Date(e.target.value) || query.dateEnd == '') {
                                                    _handleQueryInput(e.target.value, "dateInit")
                                                }
                                            }}
                                            value = {
                                                query.dateInit
                                                }
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                    <div className={`form-group col-4 mt-1 ${errors.dateEnd ? 'error' : ''}`}  style={{width: '50%'}}>
                                        <p style={{marginTop: '10px'}}>{i18n(KeyWordLocalization.TrackingDashboardPageDateEnd)}</p>
                                        <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) => {
                                                if (new Date(query.dateInit) <= new Date(e.target.value) || query.dateInit == '') {
                                                    _handleQueryInput(e.target.value, "dateEnd")
                                                }
                                            }}
                                            value = {
                                                query.dateEnd
                                                }
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                </div>
                                </form>

                            <br />
                            <div className='clean_button' onClick={() => {
                                    firstCall();
                                    setItemSelected('');
                                    setFilterSelected('all');
                                    setQuery({ 'customer': '', 'agent': '', 'lob': '', 'aiEvaluated': '', 'manualEvaluated': '', 'dateInit': '', 'dateEnd': '' });
                                }}>Reset Filter</div>
                            </div>
                        </>
                        
                    }
                <div style={{height: "20px"}}></div>
                {!isLoading  ? 
                <div className='table_home' >
                    <Grid
                        gridDefinition={[
                            {colspan: { default:12} },
                            {colspan: { default:12} }
                        ]}
                    >
                        <ContactTable
                        data={data as any}
                        />
                        
                        <Button
                            variant="primary"
                            onClick={() => onLoadTranscirption()}
                        >
                            load more
                        </Button>
                        
                        
                    </Grid>
                </div>
                
                :
                <LoadingComponent />
                }
            </ContentLayout>
        </div>
    );
};

export default AiPage;
