import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const GetAISummariesByIdApiImpl = async (params: string): Promise<any> => {

    //https://uwrueozsld.execute-api.us-east-1.amazonaws.com/Prod/search?entity=Bella+Kloberman&timestampFrom=1719723600000&timestampTo=1720310399999&language=en-US&sentimentWhat=average&sentimentWho=caller&sentimentDirection=positive

    let additionalParams = '';
    if (params !== "") {
        additionalParams = "?" + params;
    }
    
    const baseUrl = di.get<Constants>(ConstantsName).AI;

    const response = await callAPI(baseUrl + `/list${additionalParams}${additionalParams.includes('limit') ? '' : '?limit=20'}`, 'GET', {});

    //const response2 = await di.get<Host2Api>(Host2ApiName).get(baseUrl);

    const reader = response.body?.getReader();

    if (!reader) {
        throw new Error("Readable stream not supported or body is empty.");
    }
    
    let result = '';
    const decoder = new TextDecoder();

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
    }

    // Decoding the last chunk
    result += decoder.decode();
    
    return right(result);
}

export default GetAISummariesByIdApiImpl;