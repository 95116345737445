import { right } from "fp-ts/lib/Either";
import Constants, { ConstantsName } from "../../../../../constants/Constants";
import di from "../../../../../di/DependencyInjection";
import callAPI from "../../../fetchCallImplementation";

//TODO implement
const GetAISummaryByUserApiImpl = async (query: any, type: string, params: string): Promise<any> => {

    let customerParam = '';
    let lobParam = '';
    let agentParam = '';
    let aiEvaluated = '';
    let manualEvaluated = '';
    let dateInit = '';
    let dateEnd = '';
    if (query.agent) {
        agentParam = `&user_id=${query.user_id}`
    }
    if (query.lob) {
        lobParam = `&lob=${query.lob}`
    }
    if (query.customer) {
        customerParam = `customer=${query.customer}`
    }
    if (query.aiEvaluated) {
        aiEvaluated = `&aiEvaluated=${query.aiEvaluated === "True" ? true : false}`
    }
    if (query.manualEvaluated) {
        manualEvaluated = `&manualEvaluated=${query.manualEvaluated === "True" ? true : false}`
    }
    if (query.dateInit) {
        dateInit = `&dateFrom=${query.dateInit}`
    }
    if (query.dateEnd) {
        dateEnd = `&dateTo=${query.dateEnd}`
    }
    let additionalParams = '';
    if (params !== "") {
        additionalParams = "&" + params;
    }
    
    const baseUrl = di.get<Constants>(ConstantsName).AI;

    const response = await callAPI(baseUrl + `/list?${customerParam}${lobParam}${agentParam}${aiEvaluated}${manualEvaluated}${dateInit}${dateEnd}&${additionalParams}${additionalParams.includes('limit') ? '' : 'limit=20'}`, 'GET', {});

    const reader = response.body?.getReader();

    if (!reader) {
        throw new Error("Readable stream not supported or body is empty.");
    }
    
    let result = '';
    const decoder = new TextDecoder();

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
    }

    // Decoding the last chunk
    result += decoder.decode();
    
    return right(result);
}

export default GetAISummaryByUserApiImpl;